.Nav{
    width: 100%;
    padding-top: 20px;
}
.ul{
    padding: 0px;
    margin: 0px;
    list-style-type: none;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.ul>li{
    padding: 0px 5px;
    margin: 7px 0px;
    font-size: 18px;
    position: relative;
}
.ul>li::after{
    content: "";
    position: absolute;
    width: 100%;
    height: 3px;
    background-color: white;
    left: 0px;
    top: 100%;
    transform:scaleX(0);
    transition: 0.5s;
    border-radius: 1.5px;
}
.active::after{
    content: "";
    position: absolute;
    width: 100%;
    height: 3px;
    background-color: white;
    left: 0px;
    top: 100%;
    transition: 0.5s;
    border-radius: 1.5px;

}
ul>li:hover::after{
    transform: scaleX(1);
}
a{
    text-decoration: none;
    color: white;
    font-weight: bold;
    margin: 0;
    padding: 0;
}
@media only screen and (max-device-width: 480px), only screen and (min-device-width: 300px) and (max-device-width: 820px) and (orientation: landscape) {
    .Nav{
        display: block;
        padding: 0px;
    }
    .ul{
        flex-direction: row;
        justify-content: space-evenly;
        
    }
    .ul>li{
        padding: 0px 0px;
        margin: 0px;
    }
    .ul>li{
        font-size: 0.9rem;
        margin: 0px 0px;
        padding: 0px 0px;

    }
}