.container_blog{
    background-image: url(../../Image/wall.webp);
    background-attachment: fixed;
    display: block;
    text-align: center;
}
    
.Blog{
    padding: 40px;
    background-position: center;
    background-repeat: no-repeat;
    overflow-y: auto;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    
}
@media only screen and (max-device-width: 480px), only screen and (min-device-width: 300px) and (max-device-width: 820px) and (orientation: landscape){
    .container_blog{
        background-repeat: repeat;
        overflow-x: auto;
        background-attachment: local;
        background-size: cover;
    }
    .Blog{
        padding: 20px;
        justify-content: center;
    }
}
@media only screen and (min-device-width: 700px) and (max-device-width: 1400px) and (orientation: portrait){
    .Blog{
        flex-wrap: wrap;
        padding: 20px;
       
    }
}