.Contain{
    background-color: rgb(242, 247, 242);
    width: 81%;
    height: 100vh;
}
@media only screen and (max-device-width: 480px), only screen and (min-device-width: 300px) and (max-device-width: 820px) and (orientation: landscape) {
    .Contain{
        width: 100%;
    }
}

