.text{
    padding: 3px;
    padding-bottom: 5px;
    display: flex;
    overflow: hidden;
    height: 95px;
    width: 100%;
}

.text p{
    margin: 0px;
    overflow: hidden;
}
.wide{
    overflow-y: auto;
    text-align: justify;
    
}
.image{
    width: 100%;
    margin: 0 auto;
    max-height: 200px;
    max-width: 300px;
}
.date{
    align-self: flex-end;
}
.MiniBlog h2{
    margin: 0px;
    margin-top: 2px;
    padding: 5px;
    color:rgba(78, 76, 76, 0.9);
   
}
.MiniBlog h2:hover{
    cursor: pointer;
}
.MiniBlog:hover{
    transform: scale(1.02);
    border: 2px solid rgb(87, 85, 85);

}
.MiniBlog{
    width: 30%;
    border: 2px solid gray;
    display:flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 15px 8px 2px 8px;
    margin-bottom: 10px;
    transition: 0.5s;
}

.text img {
    width: 15px;
    height: 15px;
}
.text img:hover{
    cursor: pointer;
}
@media only screen and (max-device-width: 480px), only screen and (min-device-width: 300px) and (max-device-width: 820px) and (orientation: landscape){
    .MiniBlog{
        width: 90%;
    }
}
@media only screen and (min-device-width: 700px) and (max-device-width: 1400px) and (orientation: portrait){
    .MiniBlog{
        width: 45%;
    }
}