h1{
    color: rgba(7, 7, 7, 0.7);
    margin: 0px;
    padding: 20px 0px;
}
.text_style{
    color:rgba(5, 5, 5, 0.7);
    font-family: Georgia, 'Times New Roman', Times, serif;
    font-weight: 500;
    line-height: 23px;
    font-weight: 500;
}
.container{
    width: 100%;
    height: 100vh;
    display: flex;
    background-size: 100%;
    }

@media only screen and (max-device-width: 480px){
    h1{
        font-size: 1.3rem;
        padding: 10px 0px;
    }
 
}