.Skills{
    background-image: url(../../Image/wall.webp);
    background-position: center;
    text-align: center;
    align-items: center;
    flex-direction: column;
    color: rgba(7, 7, 7, 0.8); 
}

.container_small{
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    width: 80%;
}
@media only screen and (max-device-width: 480px), only screen and (min-device-width: 300px) and (max-device-width: 820px) and (orientation: landscape){
    .Skills{
        background-repeat: repeat;
        overflow-x: auto;
        background-size: cover;
        background-attachment: local;
    }
    .container_small{
        margin-top: 0px;
        width: 100%;
    }
}
