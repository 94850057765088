@media only screen and (max-device-width: 480px){
    .header{
        width: 100%;
        height: 13vh;
    }
    .profile{
        display: none;
    }
    .GetTouch{
        display: none;
     }
     .Icons{
         width: 60%;
         margin:0 auto;
     }
     .Seda, .Developer{
         margin: 0px;
         padding: 0px;
         color:rgba(78, 76, 76, 0.7);
         text-align: center;
         font-weight: 700;
     }
     .Seda_info{
         display: block;
         padding: 7px;
     }
     .Icons div{
         width: 20px;
         height: 20px;
     } 
     .Nav{
        display: none;
    }
    .About{
        height: 100%;
    }
    .App{
        flex-direction: column;
      }

}