
.about{
    background-image: url(../../Image/about.png);
    background-position: 0 -190px;
    align-items:flex-start;
    justify-content: flex-start;
    padding-top: 70px;
   
}
.main{
    background: rgba(255, 255, 255, 0.8);
    width: 65%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: justify;
    color: rgb(78, 76, 76);
    border-radius: 10px;
    margin-left: 40px;
    padding: 15px 20px;
    line-height: 27px;
    font-weight: 400;
    font-size: 1.1rem;
}
.main h1{ 
    font-size: 28px;
    line-height: 38px;
    font-weight: 450;
    margin-bottom: 5px;
}
.main br{
    display: inline-block;
    padding: 5px 0px;
}
@media only screen and (max-device-width: 480px), only screen and (min-device-width: 300px) and (max-device-width: 820px) and (orientation: landscape){
   .about{
    background-position:  0 0px;
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 0px;
   }
   .main{
       background-color: rgba(255, 255, 255, 0.6);
       width: 100%;
       height: 100vh;
       margin: 0px;
       justify-content: flex-start;
       padding: 15px;
       /* padding-top: 15px; */
   }
   .main h1{
    font-size: 1.1rem;
   }
   .main p{
      font-size: 0.9rem;
      line-height: 1.3rem;
   }

}
@media only screen and (min-device-width: 700px) and (max-device-width: 1400px) and (orientation: portrait), only screen and (min-device-width: 1024px) and (max-device-width: 1400px) and (orientation: landscape){
    .about{
        background-repeat: no-repeat;
        background-position: 0 0px;
        background-size: cover;
    }
    .main{
        width: 80%;
    }
}