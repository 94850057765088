
.App {
    display: flex;

  }
  ::-webkit-scrollbar{
    width: 6px;

  }
  ::-webkit-scrollbar-thumb{
    background-color: rgba(47, 47, 48, 0.548);
    border-radius: 5px;
  }
  @media only screen and (max-device-width: 480px){
    .App{
      flex-direction: row;
    }
  }
  @media only screen and (min-device-width: 300px) and (max-device-width: 820px) and (orientation: landscape){
    .App{
      flex-direction: column;
    }
}
 