.back_button{
    border-radius: 7px;
    outline: none;
    padding: 5px 10px;
    border: 1px solid gray;
    color:rgba(78, 76, 76, 0.9);
}
.back_button:hover{
cursor: pointer;
background-color: rgba(231, 199, 194, 0.5);
transform: scale(1.05);
transition: 0.5s;
border: 1.5px solid rgb(100, 97, 97);
box-sizing: border-box;
}
