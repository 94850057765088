.footer{
    display: none
}
@media only screen and (max-device-width: 480px), only screen and (min-device-width: 300px) and (max-device-width: 820px) and (orientation: landscape){
   .footer{
       height: 40px;
        display: block;
        background-color: rgba(231, 199, 194, 1);
        width: 100%;
        padding: 8px;
      
   }
       
}