.container_single{
    height: 100vh;
    background-image: url(../../Image/wall.jpg);
    color:rgba(5, 5, 5, 0.7);
    font-family: Georgia, 'Times New Roman', Times, serif;
    font-weight: 500;
    line-height: 23px;
  
}
.heading_single{
    text-align: center;
    margin-bottom: 20px;
    font-weight: bold;
    font-size: 1.2rem;
    color:rgba(78, 76, 76, 0.8);
}
.photo_single{
    width: 25%;
    margin-right: 1rem;
    float: left;
}
.text_single{
    padding: 0px 10px;
    text-align: justify;
    font-size: 17px;
    line-height: 1.7rem;
    font-weight: 500;
    text-indent: 2rem;
    
}
.back_button_single{
    display: flex;
    justify-content: flex-end;
}
.clear{
    background: rgba(255, 255, 255, 0.4);
    padding: 30px;
 
}
@media only screen and (max-device-width: 480px), only screen and (min-device-width: 300px) and (max-device-width: 820px) and (orientation: landscape){
    .container_single{
        background-repeat: repeat;
        overflow-x: auto;
        background-attachment: local;
        background-size: cover;
    }
    .photo_single{
        width: 100%;
    }
}