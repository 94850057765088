.download_button{
    border-radius: 7px;
    padding: 7px;
    outline: none;
    border: none;
    color:rgba(78, 76, 76, 0.7);
    font-weight: bold;
    transition: 0.5s; 
}

.download_button:hover{
    border: 2px solid rgba(78, 76, 76, 0.7);
}

@media only screen and (max-device-width: 480px), only screen and (min-device-width: 300px) and (max-device-width: 820px) and (orientation: landscape) {
    .download_button{
        padding: 4px;
        font-size: 0.7rem;
        font-weight: 400;
    }
}
