.GetTouch{
    color:rgba(78, 76, 76, 0.8);
    text-align: center;
    font-size: 0.9rem;
    font-weight: bold;
    margin-bottom: 10px;
    }
.Icons{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    }
.Icons div{
    width: 25px;
    height: 25px;
    background-size: 100%;
    background-position: center;
}
.Icons:hover{
    cursor: pointer;
}
.icon{
    transition: 0.3s;
}
.icon:hover{
transform: scale(1.2);

}

@media only screen and (max-device-width: 480px), only screen and (min-device-width: 300px) and (max-device-width: 820px) and (orientation: landscape){
    
    .GetTouch{
       display: none;
    }
    .Icons{
        width: 80%;
        margin:0 auto;
    }

    .Icons div{
        width: 17px;
        height: 17px;
    } 
}