.container_skill_single{
    font-size: 10px;
    border: 2px solid gray;
    background-color: rgba(231, 199, 194, 1);
    border-radius: 7%;
    width: 120px;
    height: 120px;
    margin: 20px;
    text-align: center;
    justify-content: center;
    display: flex;
    align-items: center;
    padding: 3px;
    box-shadow: 1px 3px 4px 1px gray;
    color:rgba(78, 76, 76, 0.9);
}
h2{
    font-size: medium;
}
.container_skill_single:hover{
    transition: 0.5s;
    transform: scale(1.05);
    background-color: rgb(243, 221, 217);

}