.header{
    width: 19%;
    height: 100vh;
    background-color: rgba(231, 199, 194, 1);
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
}
.profile{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    padding-top: 15px;
    color:rgba(78, 76, 76, 0.8);
    
}
.profileCircle{
    background-image: url(./../../Image/profile.jpg);
    width: 180px;
    height: 180px;
    border-radius: 50%;
    background-position: 0, -58;
    background-size: cover;
}
h2{
    padding: 0px;
    margin: 0px;
    font-size: 1.2rem;
}
.heading_name{
    color:rgba(78, 76, 76, 0.7);
    margin-top: 17px;
    font-size: 1.7rem;
    padding: 0px;
    margin: 0px;
}

@media only screen and (min-device-width: 300px) and (max-device-width: 820px) and (orientation: landscape),  screen and (max-device-width: 480px){ 
    .header{
        width: 100%;
        background-position: inherit;
        padding-bottom: 5px;
    }
    .profile{
        flex-direction: row;
        padding-top: 0px;
    }
    .profileCircle{
        width: 60px;
        height: 60px;
    }
    .heading_name{
        font-size: 1.2rem;
    }
    h2{
        font-size: 1rem;
    }
    .hide_contact{
        display: none;
    }
}
@media only screen and (min-device-width: 300px) and (max-device-width: 820px) and (orientation: landscape){
    .header{
        height: 29vh;
    }
}
@media only screen and (max-device-width: 480px){
    .header{
        height: 17vh;
    }
}
@media only screen and (min-device-width: 1024px) and (max-device-width: 1400px) and (orientation: landscape){
.header{
    width: 28%;
}
}
@media only screen and (min-device-width: 700px) and (max-device-width: 1400px) and (orientation: portrait){
    .header{
        width: 30%;
    }
}