.portfolio{
    background-image: url("../../Image/wall.webp");
    background-position: center;
    align-items: center;
    background-attachment: fixed;
    flex-direction: column;
    overflow-x: hidden;
    width: 100%;
    height: 100vh;
}
.container_portfolio{
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: center;
  
}
@media only screen and (max-device-width: 480px){
    .portfolio{
        background-size: cover;
        background-repeat: repeat;
        overflow-x: auto;
    }
}